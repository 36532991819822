@import 'variable';
@import 'mixin';

/* ======================================================================
 p_top
====================================================================== */


// l_main_visual_top
.l_main_visual_top {
  background-color: #fff;  
  .top {
    height: 60.55vh;
    min-height: 465px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /*padding-bottom: 32px;*/
    .txt_box {
      display: flex;
      align-items: center;
      .ttl {
        flex-shrink: 0;
        mix-blend-mode: difference;
        color: #fff;
        z-index: 1;
        pointer-events: none;
        
       .highlight {
       }       
       .light {
        display: block;      
       }
      }
      .btn {
        margin-left: -5.71vw /*78x*/;
        outline: none;
      }
    }
  }
  .bottom {
    .list {
      display: flex;
      .item {
        width: 100%;
        .img {
          width: 100%;        
        }
      }
    }

    .slide_wrap {
      position: relative;

      .swiper-slide {
        height: auto;
      }

      .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        bottom: 50px;
        text-align: right;
        padding-right: 60px;
        mix-blend-mode: difference;
      }

      .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 5px;
      }

      .circle-pagination {
        position: relative;
        width: 50px;
        height: inherit;
        z-index: 1;
        background-color: transparent;
        text-align: center;
        cursor: pointer;
        opacity: 1;
        display: inline-block;
        outline: none;
      }

      .circle-pagination .circle-pagination__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        position: relative;
      }

      .circle-pagination__inner svg {
        transform: rotate(-90deg);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
      }

      .circle-pagination__inner circle {
        fill: transparent;
        stroke: #fff;
        stroke-width: 1;
        animation: circle 5s linear forwards;
      }

      .swiper-pagination-bullet.swiper-pagination-bullet-active .circle-pagination__inner {
        color: #fff;
      }

      .circle-pagination:not(.swiper-pagination-bullet-active) .circle-pagination__inner svg {
        /* 非アクティブ時なサークルのsvgは非表示に */
        display: none;
      }
    }
  }
  
  @include tab {
    .top {
      height: auto;
      min-height: unset;
      padding-top:  21.07vw;
    }
  }
  
  
  @include sp {
    overflow: hidden;
    .top {
      height: auto;
      min-height: unset;
      padding-bottom: 5.33vw /*20px;*/;
      padding-top:  41.07vw;
      .txt_box {
        flex-direction: column;
        align-items: flex-start;
        .ttl {
         .highlight {
          font-size: 13.07vw/*49px*/;
          display: block;
         }
         .light {
          font-size: 10.13vw /*38px*/;
          margin-top: 2.93vw/*11px*/;
         }
        }
        .btn {
          align-self: flex-end;
          margin-top: -13.33vw /*-50px*/;
          margin-left: 0;
          margin-right: -11.2vw/*-42px*/;
          width: 37.33vw /*140px*/;
          height: 37.33vw /*140px*/;
        }
      }
    }
    .bottom {
      position: relative;  
      .list {
        .item {
          min-height: 500px;
          .img {
            height: 100%;
            object-fit: cover;
            object-position : center;
          }
        }
      }
      
      .slide_wrap {

        .swiper-pagination {
          z-index: 2;
        }
        .swiper-slide img {
          min-height: 503px;
          object-position: bottom;
        }

        .swiper-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {
          bottom: 30px;
          text-align: right;
          padding-right: 20px;
        }

        .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        }

        .circle-pagination {
        }

        .circle-pagination .circle-pagination__inner {
        }

        .circle-pagination__inner svg {
        }

        .circle-pagination__inner circle {
        }

        .swiper-pagination-bullet.swiper-pagination-bullet-active .circle-pagination__inner {
        }
      }
      
      
    }  
  }
}





@keyframes circle {
  0% {
    stroke-dasharray: 0 163;
  }

  99.9%,
  to {
    stroke-dasharray: 163 163;
  }
}



.sec01 {
  padding-top: 6.44vw /*88px*/;
  overflow: hidden;
  .ttl {
    font-size: 13.91vw /*190px*/;
    line-height: 1;
    white-space:nowrap;
    margin: 0 0 2.42vw/*33px*/ -1.54vw/*21px*/;    
  }
  .slide_list {
    display: flex;
    height: 100vh;
    position: relative;
    .slide_item {
      height: 95vh;
      .slide_txt {
        z-index: 1;
        position: relative;
        .slide_ttl {
          .cat {
          }
          .heading {            
          }
        }
        .info_box {
          .info_column {
            .info_column_heading {
              
            }
            .info_column_txt {
              
            }
          }
        }
        
      }
      .slide_overlay {
        
      }
      .img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;

      }
    }
  }
  
  
  
  .project_list_wrap {
    overflow: hidden;
    position: relative;
    height: 100vh;
    .project_list {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      height: 100%;
      flex-shrink: 0;
      white-space: nowrap;
      .item {
        flex-shrink: 0;
        display: flex;
        align-items: flex-end;
        width: 100vw;
        height: 95vh;
        height: 100vh;
        max-width: 92vw;
        /*min-height: 700px;*/
        position: relative;
        .link {
          position: absolute;
          top: 0;
          left: 0;
          flex-shrink: 0;
          display: flex;
          align-items: flex-end;
          width: 100vw;
          height: 95vh;
          height: 100vh;
          max-width: 92vw;
          z-index: 3;
        }
        .inner {
          width: 100%;
          /*height: 80%;*/
          padding: 1.83vw /*25px*/ 2.56vw /*35px*/;
          .slide_txt {
            z-index: 2;
            position: relative;
            .slide_ttl {
              color: #fff;
              .cat {
                border-radius: 9999px;
                border: 1px solid #FFF;
                text-align: center;
                padding: 0.73vw /*10px*/ 1.83vw /*25px*/;
                display: inline-block;

              }
              .heading {            
                margin: 0.73vw 0 2.49vw;
              }
            }
            .info_box {
              display: flex;
              .info_column {
                .info_column_heading {
                  color: $color_3;
                  margin: 0 0 0.88vw /*12px*/;
                }
                .info_column_txt {
                  color: #fff;

                }
                & + .info_column {
                  margin-left: 5.49vw /*75px*/;
                }
              }
            }

          }

        }
        .slide_overlay {
          z-index: 1;
          width: 100%;
          height: 50%;
          background-image: linear-gradient(transparent,rgba(0,0,0,.94));
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: 0%;        
        }
        .img {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          height: 100%;
        }

        & + .item {
          margin-left: 1.46vw /*20px*/;    
        }

      }
      .arr_item {
        width: 40vw;
        background-color: #fff;
      }
    }
  }
  
  .project_slide_block {
    .project_slide_list_wrap {
      overflow: visible;
      /*overflow: hidden;
      position: relative;
      height: 100vh;*/
      padding-bottom: 25.33vw /*95px*/;
      
      .project_slide_list {
        .item {
          .link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 95%;
            z-index: 3;
          }

          .inner {
            width: 100%;
            .img {
              width: 100%;
              object-fit: cover;
            }

            .slide_txt {
              margin: 5.33vw 0 0;
              .slide_ttl {
                .cat {
                  border-radius: 9999px;
                  border: 1px solid $color_1;
                  text-align: center;
                  padding: 2.67vw /*10px*/ 6.4vw /*24px*/;
                  display: inline-block;

                }
                .heading {            
                  margin: 4vw /*15px*/ 0 5.33vw /*20px*/;
                }
              }
              .info_box {
                /*display: flex;*/
                .info_column {
                  .info_column_heading {
                    color: $color_3;
                    margin: 0 0 1.33vw /* 5px*/;
                  }
                  .info_column_txt {
                    /*color: #fff;*/

                  }
                  & + .info_column {
                    margin-top: 5.33vw /*20px*/;
                  }
                }
              }

            }
            .btn {
              position: absolute;
              top: 5.33vw;
              left: 50%;
              transform: translateX(-50%);
              width: 38.2vw;
              height: 38.2vw;
              .img {
                width: 13.07vw;
              }
            }
          }
        }
      }
      .project-pagination {
        width: calc(100% - 40px);
        height: 2px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 17.07vw /*64px*/;
        top: auto;
        background-color: $color_4;
        .swiper-pagination-progressbar-fill {
          background-color: $color_1;
        }        
      }
      .swiper-button-next, 
      .swiper-button-prev {
        position: absolute;
        top: auto;
        bottom: 0;
        width: 9.33vw /*35px*/;
        height: 9.33vw /*35px*/;;
        border-radius: 9999px;
        border: 1px solid $color_1;
        margin-top: 0;
        color: $color_1;
      }
      .swiper-button-prev {
        left: 20px;
      }      
      .swiper-button-next {
        right: auto;
        left: calc(20px + 10.67vw /*40px */);
      }      
      .swiper-button-next::after,
      .swiper-button-prev::after {
        position: absolute;
        content: "";
        background-image: url("../img/common/icon_arrow_sp.svg");
        background-size: 3.2vw /*12px*/;
        background-position: center;
        width: 3.2vw /*12px*/;
        height: 3.2vw /*12px*/;
      }      
      .swiper-button-next::after{
      }      
      .swiper-button-prev::after {
        transform: rotate(180deg)
      }      
    }
    .arr_item {
      margin-top: 20vw /*75px*/;
      /*width: 40vw;*/
    }
  }

  @include sp {
    padding: 26.67vw /*100px*/ 0;
    .ttl {
      font-size: 13.87vw /*51px*/;
      margin: 0 0 5.33vw/*20px*/ -1.07vw/*4px*/;    
    }
    
  }

}


.sec02 {
  padding: 12.23vw/*167px*/ 0 14.64vw/*200px*/;
  overflow: hidden;
  .move_txt_wrap {
    color: $color_2;
    white-space:nowrap;
    display: flex;
    .move_txt {
      padding-right: 4.39vw /*60px*/;
    }
    .move_txt:nth-of-type(1) {
      animation: loop 50s -25s linear infinite;
    }
    .move_txt:nth-of-type(2) {
      animation: loop2 50s linear infinite;
    }    
  
    &.reverse_txt {
      transform: rotateY( 180deg );
      backface-visibility: unset;
    }
    & + .move_txt {
      margin-top: -1.17vw /*10px*/;
    }
  }
  .ttl {
    text-align: right;
    margin: -8.2vw /*-112px*/ -3.44vw/*47px*/ 0 0;
    position: relative;    
  }
  .inner {
    padding-top: 2.56vw /*35px*/;
    
  }
  .txt_box {
    text-align: right;
    .txt {
      & + .txt {
        margin: 1em 0 0;
      }
    }
    &.en {
      
    }
    &.jp {
      margin: 2.93vw /*40px*/ 0 0;
      font-family: $font_2;
      color: $color_3;
      
    }
  }
  .btn {
    margin: 4.76vw /*65px*/ 0 0;
  }
  
  

  @include sp {
    padding: 0 0 26.67vw /*100px*/;
    .move_txt {

      &.reverse_txt {
      }
      & + .move_txt {
        margin-top: -1.33vw /*5px*/;
      }
    }
    .ttl {
      text-align: left;
      margin: -12.27vw /*-46px*/ 0 0;
      padding: 0 20px;
    }
    .inner {
      padding-top: 13.33vw /*50px*/;

    }
    .txt_box {
      text-align: left;
      .txt {
        & + .txt {
        }
      }
      &.en {

      }
      &.jp {
        margin: 10.67vw /*40px*/ 0 0;
      }
    }
    .btn {
      margin: 20vw /*75px*/ 0 0;
    }
  
  }
}

@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
  
@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}
  
.sec03 {
  padding: 0 0 14.64vw/*200px*/;
  overflow: hidden;
  .list {
    display: flex;
    .item {
      .img {
        
      }
    }
  }
  
  .swiper-wrapper {
    transition-timing-function: linear !important;
  }
  .swiper-slide {
    width: max-content;
  }
  .slide {
    width: /*19.62vw*/268px;
    transition: width .8s;
  }
  .slide-media {
    position: relative;
    overflow: hidden;
    height: /*19.62vw*/ 268px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .slide-content {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 3.2rem 1.6rem 1.6rem;
    transition: opacity .8s;
    opacity: 0;
    color: #fff;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 50%);
  }
  .slide-title {
    font-size: 1.4rem;
    line-height: 1.4;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
    
  
  .ttl {
    white-space:nowrap;
    display: flex;
    justify-content: center;
    margin: 0.95vw 0 0 -3.7vw;
  }
  .btn {
    margin: 5.49vw /*75px*/ 0 0;    
  }
  
  @include pc_tab {
    .slide {
      &:hover {
        width: calc(19.62vw268px * 1.5);
      }
      &:hover .slide-content {
        opacity: 1;
      }
    }
  }

  @include sp {
    padding: 0 0 26.67vw/*100px*/;
    .list {
      .item {
        .img {

        }
      }
    }
    .slide {
      width: 127px;
    }
    .slide-media {
      height: 127px;
    }
    
    .ttl {
      margin: 4vw/*15px*/ 0 0;
    }
    .btn {
      margin: 20vw /*75px*/ 0 0;    
    }
  
  }
}

.sec04 {  
  @include sp {  
  }
}




